import React, {useEffect } from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'
import { connect, useSelector, useDispatch } from "react-redux"

const mapStateToProps = state => {
  return { 
      theme: state.theme
  }
}

const mapDispatchToProps = dispatch => {
    return { 
        setThemeStore: (theme) => dispatch({type: `SET_THEME`, theme }),
    }
}

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, theme, setThemeStore}) => {
    
    //const theme = useSelector(state => state.theme)
    //const dispatch = useDispatch()
    const getThemeClass = (theme) => {
        return (theme == "light"? styles.light: styles.dark)
    }
    
    return(
      <div id="layout"
          className={getThemeClass("dark")}>
        <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
        <div 
            className={styles.content}
            >{children}
        </div>
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>
            <div className={styles.siteInfo}>
              &copy; {new Date().getFullYear()}, <a href='http://www.droplee.com'>Droplee.com</a>
            </div>
          </div>
        </footer>
      </div>
    )}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
