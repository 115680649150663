import {Link} from 'gatsby'
import React, {useEffect } from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'
import { useSelector, useDispatch } from "react-redux"

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => { 
    
    const theme = useSelector(state => state.theme)
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(localStorage.getItem("theme") &&
           localStorage.getItem("theme")=="light"){
            setTheme("light")
        }else{
            setTheme("dark")
        }
   });
    
    const setTheme = (theme) => {
        localStorage.setItem("theme",theme)
        setThemeStore(theme)
        console.log("[setTheme]", theme);
    }
    
    const setThemeStore = (theme) => {
        dispatch({type: `SET_THEME`, theme })
    }
    
    const toggleTheme = () => {
        console.log("[toggleTheme]", localStorage.getItem("theme"));
        setTheme(localStorage.getItem("theme") == "light" ? "dark": "light");
    };
    
    return(
        <div className={styles.root}>
            <div className={styles.wrapper}>
              <div className={styles.branding}>
                <Link to='/'>{siteTitle}</Link>
              </div>

              <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
                <Icon symbol='hamburger' />
              </button>

              <nav className={cn(styles.nav, showNav && styles.showNav)}>
                <ul>
                  <li>
                    <Link to='/archive/'>Archive</Link>
                  </li>
                  <li>
                    <div className={styles.theme} style={{cursor: "pointer"}} onClick={toggleTheme}>Theme</div>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
    )
}

export default Header
